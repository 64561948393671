<template>
  <b-card>
    <b-form>
      <div class="flex items-center gap-3">
        <b-form-group label="Tìm kiếm" style="flex: 1">
          <b-form-input
            v-model="filters.keyword"
            placeholder="Nhập tên chương trình"
            @input="handleDebounce"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Ngày tạo chương trình" style="flex: 1">
          <b-form-datepicker
            placeholder="Chọn ngày tạo"
            v-model="filters.created_at"
            @input="handleDebounce"
          ></b-form-datepicker>
        </b-form-group>
      </div>
    </b-form>
    <div class="flex justify-between mt-1">
      <b-button variant="outline-primary" @click="handleResetFilter">
        {{
        $t("Làm mới bộ lọc")
        }}
      </b-button>
      <div class="flex gap-2">
        <b-button variant="primary" class="w-fit" @click="handleDeposit">
          {{
          $t("Nạp điểm")
          }}
        </b-button>
        <b-button variant="primary" class="w-fit" @click="handleCreate">
          {{
          $t("Tạo mới Chương trình")
          }}
        </b-button>
      </div>
    </div>
    <b-table
      :busy="isLoading"
      striped
      hover
      :items="dataTable"
      :fields="fieldsTable"
      class="mt-2 mb-3"
      show-empty
      ref="refDataTable"
    >
      <template #table-busy>
        <div class="text-center my-2 flex flex-col gap-2 justify-center">
          <div class="text-center">
            <b-spinner class="align-middle"></b-spinner>
          </div>
          <strong>Đang tải</strong>
        </div>
      </template>
      <template
        #cell(index)="data"
      >{{ (pagination.currentPage - 1) * pagination.perPage + data.index + 1 }}</template>
      <template #cell(created_at)="data">{{ appUtils.formatDateTime(data.item.created_at) }}</template>
      <template #cell(point)="data">{{ appUtils.numberFormat(data.item.point) }}</template>
      <template #cell(money_amount)="data">
        <div class="text-right">{{ appUtils.numberFormat(data.item.money_amount, "VND") }}</div>
      </template>
    </b-table>
    <Pagination
      class="mx-0"
      :meta="metaData"
      :perPage="pagination.perPage"
      :currentPage="pagination.currentPage"
      :totals="pagination.totals"
      @setCurrentPage="handleSetCurrentPage"
      @setPerPage="handleSetPerPage"
    />
  </b-card>
</template>
<script>
import Pagination from '@/layouts/components/Pagination.vue'
import { HODO_CREDIT_TYPE } from '@/utils/constant'
import appUtils from '@/utils/appUtils'
import { debounce } from 'lodash'

export default {
  name: 'TopUpPoint',
  components: { Pagination },
  data() {
    return {
      filters: {
        keyword: '',
        duration: '',
        created_at: '',
        to_date: ''
      },

      fieldsTable: [
        {
          key: 'index',
          label: 'STT'
        },
        {
          key: 'name',
          label: 'Tên chương trình'
        },
        {
          key: 'create_by_info.name',
          label: 'Người thực hiện'
        },
        {
          key: 'created_at',
          label: 'Ngày tạo'
        },
        {
          key: 'point',
          label: 'Số điểm'
        },
        {
          key: 'money_amount',
          label: 'Tổng tiền',
          class: 'text-right'
        }
      ],
      dataTable: [],
      pagination: {
        perPage: 10,
        currentPage: 1,
        totals: 24
      },
      appUtils,
      isLoading: false
    }
  },
  created() {
    this.handleGetDeposit()
  },
  computed: {
    metaData() {
      const localItemsCount = this.$refs?.refDataTable
        ? this.$refs?.refDataTable.localItems?.length
        : 0
      return {
        from:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          (localItemsCount ? 1 : 0),
        to:
          this.pagination.perPage * (this.pagination.currentPage - 1) +
          localItemsCount,
        of: this.pagination.totals
      }
    }
  },
  methods: {
    handleSetCurrentPage(value) {
      this.pagination.currentPage = value
      this.handleGetDeposit()
    },
    handleSetPerPage(value) {
      this.pagination.perPage = value
      this.pagination.currentPage = 1
      this.handleGetDeposit()
    },
    handleCreate() {
      this.$router.push({ name: 'CreateTopUpPointTransaction' })
    },
    handleResetFilter() {
      this.filters = {
        keyword: '',
        duration: '',
        from_date: '',
        to_date: ''
      }
      this.handleGetDeposit()
    },
    async handleGetDeposit() {
      this.isLoading = true
      const userData = appUtils.getLocalUser()

      if (!userData.supplierInfo?.org_id) return
      try {
        const params = {
          page_num: this.pagination.currentPage,
          page_size: this.pagination.perPage,
          org_id: userData.supplierInfo?.org_id,
          type: HODO_CREDIT_TYPE.deposit,
          sort_by: 'created_at',
          order: 'desc',
          keyword: this.filters.keyword,
          created_at: this.filters.created_at
        }

        const response = await this.$rf
          .getRequest('CommonRequest')
          .getListPointProgram(params)

        this.dataTable = response.data?.data || []
        this.pagination.totals = response.data?.page?.total
        this.isLoading = false
      } catch (error) {
        this.isLoading = false
        console.log(error)
      }
    },
    handleDeposit() {
      this.$router.push({
        name: 'DepositPoint'
      })
    },
    handleDebounce: debounce(function() {
      this.pagination.currentPage = 1
      this.handleGetDeposit()
    }, 300)
  }
}
</script>
<style lang="scss" scoped>
.container-credit {
  background: #fff;
}
</style>